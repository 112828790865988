import { RouteConfig } from 'vue-router';
import { PermissionLevel } from '@/utils/permission';
import { loanSystemRoutes } from './LoanSystemRoutes';
import { loanSystemRoutesAdmin } from './LoanSystemRoutes';

const LazyLayout = () => import('@/layout/index.vue');

export default [
  {
    children: [
      // #region Dossier
      {
        component: () => import('@/pages/dossiers/index.vue'),
        meta: {
          permissions: [{ Dossier: PermissionLevel.Read }],
          title: 'menu_dossiers'
        },
        name: 'Dossiers',
        path: 'dossiers'
      },
      {
        component: () => import('@/pages/dossiers/create.vue'),
        hidden: true,
        meta: {
          permissions: [{ Dossier: PermissionLevel.Write }],
          title: 'createDossier'
        },
        name: 'CreateDossier',
        path: 'dossiers/create'
      },
      {
        component: () => import('@/pages/dossiers/edit.vue'),
        hidden: true,
        meta: {
          permissions: [{ Dossier: PermissionLevel.Read }],
          title: 'editDossier'
        },
        name: 'EditDossier',
        path: 'dossiers/edit/:id(\\d+)'
      },
      {
        component: () => import('@/pages/dossiers/documents/create.vue'),
        hidden: true,
        meta: {
          permissions: [{ Document: PermissionLevel.Write }],
          title: 'createDocument'
        },
        name: 'CreateDocument',
        path: 'dossiers/:dossierID(\\d+)/documents/create'
      },
      {
        component: () => import('@/pages/dossiers/documents/edit.vue'),
        hidden: true,
        meta: {
          permissions: [{ Status: PermissionLevel.Read }],
          title: 'editDocument'
        },
        name: 'EditDocument',
        path: 'Document/edit/:id(\\d+)'
      },
      {
        component: () => import('@/pages/dossiers/loans/create.vue'),
        hidden: true,
        meta: {
          permissions: [{ Document: PermissionLevel.Write }],
          title: 'createLoan'
        },
        name: 'CreateLoan',
        path: 'dossiers/:dossierID(\\d+)/loans/create'
      },
      {
        component: () => import('@/pages/dossiers/loans/edit.vue'),
        hidden: true,
        meta: {
          permissions: [{ Status: PermissionLevel.Read }],
          title: 'editLoan'
        },
        name: 'EditLoan',
        path: 'Loan/edit/:id(\\d+)'
      },
      {
        component: () => import('@/pages/dossiers/loans/view.vue'),
        hidden: true,
        meta: {
          permissions: [{ Status: PermissionLevel.Read }],
          title: 'viewLoan'
        },
        name: 'ViewLoan',
        path: 'Loan/view/:id(\\d+)'
      },
      {
        component: () => import('@/pages/dossiers/dossieractions/create.vue'),
        hidden: true,
        meta: {
          permissions: [{ DossierAction: PermissionLevel.Write }],
          title: 'createDossierAction'
        },
        name: 'CreateDossierAction',
        path: 'dossieractions/create/:dossierID(\\d+)'
      },
      {
        component: () => import('@/pages/dossiers/dossieractions/edit.vue'),
        hidden: true,
        meta: {
          permissions: [{ DossierAction: PermissionLevel.Read }],
          title: 'editDossierAction'
        },
        name: 'EditDossierAction',
        path: 'dossieractions/edit/:id(\\d+)?dossierID=:dossierID(\\d+)'
      },
      // #endregion

      // #region dossier action
      {
        component: () => import('@/pages/dossiers/dossieractions/index.vue'),
        hidden: true,
        meta: {
          permissions: [{ DossierAction: PermissionLevel.Read }],
          title: 'menu_dossier_actions'
        },
        name: 'DossierAction',
        path: 'dossieractions'
      },
      // #endregion
      ...loanSystemRoutes
    ],
    component: LazyLayout,
    meta: { icon: 'tree', title: 'menu_loansystem' },
    name: 'LoanSystem',
    path: '/loansystem'
  } as RouteConfig,
  {
    children: [
      {
        component: () => import('@/pages/dossiers/indexmanager.vue'),
        meta: {
          permissions: [{ AgreementManager: PermissionLevel.Read }],
          title: 'menu_agreement_manager'
        },
        name: 'DossiersManager',
        path: 'dossiersManager'
      }
    ],
    component: LazyLayout,
    meta: { icon: 'tree', title: 'menu_loansystem' },
    name: 'Manager',
    path: '/manager'
  } as RouteConfig,
  {
    children: [
      {
        component: () => import('@/pages/dossiers/indexboard.vue'),
        meta: {
          permissions: [{ AgreementBoard: PermissionLevel.Read }],
          title: 'menu_agreement_board'
        },
        name: 'DossiersBoard',
        path: 'dossiersBoard'
      }
    ],
    component: LazyLayout,
    meta: { icon: 'tree', title: 'menu_loansystem' },
    name: 'Board',
    path: '/board'
  } as RouteConfig,
  {
    children: [
      {
        component: () => import('@/pages/dossiers/indexboardTK.vue'),
        meta: {
          permissions: [{ AgreementBoard: PermissionLevel.Read }],
          title: 'menu_information_board'
        },
        name: 'DossiersBoardTk',
        path: 'dossiersBoardTk'
      }
    ],
    component: LazyLayout,
    meta: { icon: 'tree', title: 'menu_loansystem' },
    name: 'BoardTk',
    path: '/boardTk'
  } as RouteConfig,
  {
    children: [
      {
        component: () => import('@/pages/reports/actionReport/index.vue'),
        meta: {
          permissions: [{ Report: PermissionLevel.Read }],
          title: 'menu_reportActions'
        },
        name: 'ReportActions',
        path: 'reports/actionReport'
      },
      {
        component: () => import('@/pages/reports/dossiersNaarBestuurTb/index.vue'),
        meta: {
          permissions: [{ Report: PermissionLevel.Read }],
          title: 'menu_reportDossiersNaarBestuurTb'
        },
        name: 'ReportDossiersNaarBestuurTb',
        path: 'reports/dossiersNaarBestuurTb'
      },
      {
        component: () => import('@/pages/reports/dossiersNaarBestuurTk/index.vue'),
        meta: {
          permissions: [{ Report: PermissionLevel.Read }],
          title: 'menu_reportDossiersNaarBestuurTk'
        },
        name: 'ReportDossiersNaarBestuurTk',
        path: 'reports/dossiersNaarBestuurTk'
      },
      {
        component: () => import('@/pages/reports/totaleLeningenGiften/index.vue'),
        meta: {
          permissions: [{ Report: PermissionLevel.Read }],
          title: 'menu_reporttotaleLeningenGiften'
        },
        name: 'TotaleLeningenGiften',
        path: 'reports/totaleLeningenGiften'
      }
    ],
    component: LazyLayout,
    meta: { icon: 'tree', title: 'menu_reports' },
    name: 'Reports',
    path: '/reports'
  } as RouteConfig,
  {
    children: [
      // #region Security
      {
        component: () => import('@/pages/admin/security/roles/index.vue'),
        meta: {
          permissions: [{ Role: PermissionLevel.Read }],
          title: 'menu_admin_roles'
        },
        name: 'Roles',
        path: 'roles'
      },
      {
        component: () => import('@/pages/admin/security/roles/create.vue'),
        hidden: true,
        meta: {
          icon: 'edit',
          permissions: [{ Role: PermissionLevel.Write }],
          title: 'createRole'
        },
        name: 'CreateRole',
        path: 'roles/create'
      },
      {
        component: () => import('@/pages/admin/security/roles/edit.vue'),
        hidden: true,
        meta: {
          noCache: true,
          permissions: [{ Role: PermissionLevel.Read }],
          title: 'editRole'
        },
        name: 'EditRole',
        path: 'roles/edit/:id(\\d+)'
      },
      {
        component: () => import('@/pages/admin/security/users/index.vue'),
        meta: {
          permissions: [{ User: PermissionLevel.Read }],
          title: 'menu_admin_users'
        },
        name: 'Users',
        path: 'users'
      },
      {
        component: () => import('@/pages/admin/security/users/create.vue'),
        hidden: true,
        meta: {
          icon: 'edit',
          permissions: [{ User: PermissionLevel.Write }],
          title: 'createUser'
        },
        name: 'CreateUser',
        path: 'users/create'
      },
      {
        component: () => import('@/pages/admin/security/users/edit.vue'),
        hidden: true,
        meta: {
          noCache: true,
          permissions: [{ User: PermissionLevel.Read }],
          title: 'editUser'
        },
        name: 'EditUser',
        path: 'users/edit/:id(\\d+)'
      }
    ],
    component: LazyLayout,
    meta: { icon: 'peoples', title: 'menu_admin_security' },
    name: 'Security',
    path: '/security'
  } as RouteConfig,
  // #endregion
  {
    children: [
      // #region aanvrager applicant
      {
        component: () => import('@/pages/admin/applicants/index.vue'),
        meta: {
          permissions: [{ Applicant: PermissionLevel.Read }],
          title: 'menu_applicants'
        },
        name: 'Applicants',
        path: 'applicant'
      },
      {
        component: () => import('@/pages/admin/applicants/create.vue'),
        hidden: true,
        meta: {
          permissions: [{ Applicant: PermissionLevel.Write }],
          title: 'createApplicant'
        },
        name: 'CreateApplicant',
        path: 'applicants/create'
      },
      {
        component: () => import('@/pages/admin/applicants/edit.vue'),
        hidden: true,
        meta: {
          permissions: [{ Applicant: PermissionLevel.Read }],
          title: 'editApplicant'
        },
        name: 'EditApplicant',
        path: 'applicants/edit/:id(\\d+)'
      },
      // #endregion
      // #region action status
      {
        component: () => import('@/pages/admin/actionstatus/index.vue'),
        meta: {
          permissions: [{ ActionStatus: PermissionLevel.Read }],
          title: 'menu_action_status'
        },
        name: 'ActionStatus',
        path: 'actionstatus'
      },
      {
        component: () => import('@/pages/admin/actionstatus/create.vue'),
        hidden: true,
        meta: {
          permissions: [{ ActionStatus: PermissionLevel.Write }],
          title: 'createActionStatus'
        },
        name: 'CreateActionStatus',
        path: 'actionstatus/create'
      },
      {
        component: () => import('@/pages/admin/actionstatus/edit.vue'),
        hidden: true,
        meta: {
          permissions: [{ ActionStatus: PermissionLevel.Read }],
          title: 'editActionStatus'
        },
        name: 'EditActionStatus',
        path: 'actionstatus/edit/:id(\\d+)'
      },
      // #endregion
      // #region action types
      {
        component: () => import('@/pages/admin/actiontypes/index.vue'),
        meta: {
          permissions: [{ ActionType: PermissionLevel.Read }],
          title: 'menu_action_types'
        },
        name: 'ActionTypes',
        path: 'actiontypes'
      },
      {
        component: () => import('@/pages/admin/actiontypes/create.vue'),
        hidden: true,
        meta: {
          permissions: [{ ActionType: PermissionLevel.Write }],
          title: 'createActionType'
        },
        name: 'CreateActionType',
        path: 'actiontypes/create'
      },
      {
        component: () => import('@/pages/admin/actiontypes/edit.vue'),
        hidden: true,
        meta: {
          permissions: [{ ActionType: PermissionLevel.Read }],
          title: 'editActionType'
        },
        name: 'EditActionType',
        path: 'actiontypes/edit/:id(\\d+)'
      },
      // #endregion
      // #region BC codes
      {
        component: () => import('@/pages/admin/bccodes/index.vue'),
        meta: {
          permissions: [{ BCcode: PermissionLevel.Read }],
          title: 'menu_bccodes'
        },
        name: 'BCcode',
        path: 'bccodes'
      },
      {
        component: () => import('@/pages/admin/bccodes/create.vue'),
        hidden: true,
        meta: {
          permissions: [{ BCcode: PermissionLevel.Write }],
          title: 'createBCcode'
        },
        name: 'Createbccode',
        path: 'bccodes/create'
      },
      {
        component: () => import('@/pages/admin/bccodes/edit.vue'),
        hidden: true,
        meta: {
          permissions: [{ BCcode: PermissionLevel.Read }],
          title: 'editBCcode'
        },
        name: 'Editbccode',
        path: 'bccodes/edit/:id(\\d+)'
      },
      // #endregion
      // #region functie function
      {
        component: () => import('@/pages/admin/functions/index.vue'),
        meta: {
          permissions: [{ Function: PermissionLevel.Read }],
          title: 'menu_functions'
        },
        name: 'Functions',
        path: 'functions'
      },
      {
        component: () => import('@/pages/admin/functions/create.vue'),
        hidden: true,
        meta: {
          permissions: [{ Function: PermissionLevel.Write }],
          title: 'createFunctions'
        },
        name: 'Createapplicantfunction',
        path: 'functions/create'
      },
      {
        component: () => import('@/pages/admin/functions/edit.vue'),
        hidden: true,
        meta: {
          permissions: [{ Function: PermissionLevel.Read }],
          title: 'editFunction'
        },
        name: 'Editapplicantfunction',
        path: 'functions/edit/:id(\\d+)'
      },
      // #endregion
      // #region geslacht gender
      {
        component: () => import('@/pages/admin/genders/index.vue'),
        meta: {
          permissions: [{ Gender: PermissionLevel.Read }],
          title: 'menu_genders'
        },
        name: 'Genders',
        path: 'genders'
      },
      {
        component: () => import('@/pages/admin/genders/create.vue'),
        hidden: true,
        meta: {
          permissions: [{ Gender: PermissionLevel.Write }],
          title: 'createGender'
        },
        name: 'CreateGender',
        path: 'genders/create'
      },
      {
        component: () => import('@/pages/admin/genders/edit.vue'),
        hidden: true,
        meta: {
          permissions: [{ Gender: PermissionLevel.Read }],
          title: 'editGender'
        },
        name: 'EditGender',
        path: 'genders/edit/:id(\\d+)'
      },
      // #endregion
      // #region leningtypes loantypes
      {
        component: () => import('@/pages/admin/loantypes/index.vue'),
        meta: {
          permissions: [{ LoanType: PermissionLevel.Read }],
          title: 'menu_loan_types'
        },
        name: 'Loantype',
        path: 'loantypes'
      },
      {
        component: () => import('@/pages/admin/loantypes/create.vue'),
        hidden: true,
        meta: {
          permissions: [{ LoanType: PermissionLevel.Write }],
          title: 'createLoantype'
        },
        name: 'CreateLoanType',
        path: 'loantypes/create'
      },
      {
        component: () => import('@/pages/admin/loantypes/edit.vue'),
        hidden: true,
        meta: {
          permissions: [{ LoanType: PermissionLevel.Write }],
          title: 'editLoantype'
        },
        name: 'EditLoanType',
        path: 'loantypes/edit/:id(\\d+)'
      },
      // #endregion
      // #region reden reasons
      {
        component: () => import('@/pages/admin/reasons/index.vue'),
        meta: {
          permissions: [{ Reason: PermissionLevel.Read }],
          title: 'menu_reasons'
        },
        name: 'Reasons',
        path: 'reasons'
      },
      {
        component: () => import('@/pages/admin/reasons/create.vue'),
        hidden: true,
        meta: {
          permissions: [{ Reason: PermissionLevel.Write }],
          title: 'createReason'
        },
        name: 'CreateReason',
        path: 'reasons/create'
      },
      {
        component: () => import('@/pages/admin/reasons/edit.vue'),
        hidden: true,
        meta: {
          permissions: [{ Reason: PermissionLevel.Read }],
          title: 'editReason'
        },
        name: 'EditReason',
        path: 'reasons/edit/:id(\\d+)'
      },
      // #endregion
      // #region schuldenladder debtsteps
      {
        component: () => import('@/pages/admin/debtsteps/index.vue'),
        meta: {
          permissions: [{ DebtStep: PermissionLevel.Read }],
          title: 'menu_debt_ladder'
        },
        name: 'Debtladder',
        path: 'debtSteps'
      },
      {
        component: () => import('@/pages/admin/debtsteps/create.vue'),
        hidden: true,
        meta: {
          permissions: [{ DebtStep: PermissionLevel.Write }],
          title: 'createDebtStep'
        },
        name: 'CreateDebtStep',
        path: 'debtSteps/create'
      },
      {
        component: () => import('@/pages/admin/debtsteps/edit.vue'),
        hidden: true,
        meta: {
          permissions: [{ DebtStep: PermissionLevel.Write }],
          title: 'editDebtStep'
        },
        name: 'EditDebtStep',
        path: 'debtSteps/edit/:id(\\d+)'
      },
      // #endregion
      // #region documenttypes documentstatus
      {
        component: () => import('@/pages/admin/documentstatuses/index.vue'),
        meta: {
          permissions: [{ DocumentType: PermissionLevel.Read }],
          title: 'menu_document_statuses'
        },
        name: 'DocumentStatus',
        path: 'documentstatuses'
      },
      {
        component: () => import('@/pages/admin/documentstatuses/create.vue'),
        hidden: true,
        meta: {
          permissions: [{ DocumentType: PermissionLevel.Write }],
          title: 'createDocumentStatus'
        },
        name: 'CreateDocumentStatus',
        path: 'documentstatuses/create'
      },
      {
        component: () => import('@/pages/admin/documentstatuses/edit.vue'),
        hidden: true,
        meta: {
          permissions: [{ DocumentType: PermissionLevel.Read }],
          title: 'editDocumentStatus'
        },
        name: 'EditDocumentStatus',
        path: 'documentstatuses/edit/:id(\\d+)'
      },
      // #endregion
      // #region documenttypes documenttypes
      {
        component: () => import('@/pages/admin/documenttypes/index.vue'),
        meta: {
          permissions: [{ DocumentType: PermissionLevel.Read }],
          title: 'menu_document_types'
        },
        name: 'DocumentType',
        path: 'documenttypes'
      },
      {
        component: () => import('@/pages/admin/documenttypes/create.vue'),
        hidden: true,
        meta: {
          permissions: [{ DocumentType: PermissionLevel.Write }],
          title: 'createDocumentType'
        },
        name: 'CreateDocumentType',
        path: 'documenttypes/create'
      },
      {
        component: () => import('@/pages/admin/documenttypes/edit.vue'),
        hidden: true,
        meta: {
          permissions: [{ DocumentType: PermissionLevel.Read }],
          title: 'editDocumentType'
        },
        name: 'EditDocumentType',
        path: 'documenttypes/edit/:id(\\d+)'
      },
      // #endregion
      // #region status
      {
        component: () => import('@/pages/admin/statuses/index.vue'),
        meta: {
          permissions: [{ Status: PermissionLevel.Read }],
          title: 'menu_statuses'
        },
        name: 'Statuses',
        path: 'statuses'
      },
      {
        component: () => import('@/pages/admin/statuses/create.vue'),
        hidden: true,
        meta: {
          permissions: [{ Status: PermissionLevel.Write }],
          title: 'createStatus'
        },
        name: 'CreateStatus',
        path: 'statuses/create'
      },
      {
        component: () => import('@/pages/admin/statuses/edit.vue'),
        hidden: true,
        meta: {
          permissions: [{ Status: PermissionLevel.Read }],
          title: 'editStatus'
        },
        name: 'EditStatus',
        path: 'statuses/edit/:id(\\d+)'
      },
      // #endregion
      // #region templates
      {
        component: () => import('@/pages/admin/templates/index.vue'),
        meta: {
          permissions: [{ Template: PermissionLevel.Read }],
          title: 'menu_templates'
        },
        name: 'Templates',
        path: 'templates'
      },
      {
        component: () => import('@/pages/admin/templates/create.vue'),
        hidden: true,
        meta: {
          permissions: [{ Template: PermissionLevel.Write }],
          title: 'createTemplate'
        },
        name: 'CreateTemplate',
        path: 'templates/create'
      },
      {
        component: () => import('@/pages/admin/templates/edit.vue'),
        hidden: true,
        meta: {
          permissions: [{ Template: PermissionLevel.Read }],
          title: 'editTemplate'
        },
        name: 'EditTemplate',
        path: 'templates/edit/:id(\\d+)'
      },
      // #endregion
      // #region vertragingen delays
      {
        component: () => import('@/pages/admin/delays/index.vue'),
        meta: {
          permissions: [{ Delay: PermissionLevel.Read }],
          title: 'menu_delays'
        },
        name: 'Delay',
        path: 'delays'
      },
      {
        component: () => import('@/pages/admin/delays/create.vue'),
        hidden: true,
        meta: {
          permissions: [{ Delay: PermissionLevel.Write }],
          title: 'createDelay'
        },
        name: 'CreateDelay',
        path: 'delays/create'
      },
      {
        component: () => import('@/pages/admin/delays/edit.vue'),
        hidden: true,
        meta: {
          permissions: [{ Delay: PermissionLevel.Read }],
          title: 'editDelay'
        },
        name: 'EditDelay',
        path: 'delays/edit/:id(\\d+)'
      },
      // #endregion
      // #region werkzaam bij companies
      {
        component: () => import('@/pages/admin/companies/index.vue'),
        meta: {
          permissions: [{ Company: PermissionLevel.Read }],
          title: 'menu_companies'
        },
        name: 'Company',
        path: 'companies'
      },
      {
        component: () => import('@/pages/admin/companies/create.vue'),
        hidden: true,
        meta: {
          permissions: [{ Company: PermissionLevel.Write }],
          title: 'createCompany'
        },
        name: 'CreateCompany',
        path: 'companies/create'
      },
      {
        component: () => import('@/pages/admin/companies/edit.vue'),
        hidden: true,
        meta: {
          permissions: [{ Company: PermissionLevel.Read }],
          title: 'editCompany'
        },
        name: 'EditCompany',
        path: 'companies/edit/:id(\\d+)'
      },
      // #endregion
      // PLOP: Application routes
      {
        component: () => import('@/pages/admin/loanstatuschangetriggers/index.vue'),
        meta: {
          permissions: [{ LoanStatusChangeTrigger: PermissionLevel.Read }],
          title: 'menu_loan_status_change_triggers'
        },
        name: 'LoanStatusChangeTriggers',
        path: 'loan-status-change-triggers'
      },
      {
        component: () => import('@/pages/admin/loanstatuschangetriggers/create.vue'),
        hidden: true,
        meta: {
          permissions: [{ LoanStatusChangeTrigger: PermissionLevel.Write }],
          title: 'createLoanStatusChangeTrigger'
        },
        name: 'CreateLoanStatusChangeTrigger',
        path: 'loan-status-change-triggers/create'
      },
      {
        component: () => import('@/pages/admin/loanstatuschangetriggers/edit.vue'),
        hidden: true,
        meta: {
          permissions: [{ LoanStatusChangeTrigger: PermissionLevel.Read }],
          title: 'editLoanStatusChangeTrigger'
        },
        name: 'EditLoanStatusChangeTrigger',
        path: 'loan-status-change-triggers/edit/:id(\\d+)'
      },
      {
        component: () => import('@/pages/admin/reasonsdenial/index.vue'),
        meta: {
          permissions: [{ ReasonDenial: PermissionLevel.Read }],
          title: 'menu_reasons_denial'
        },
        name: 'ReasonsDenial',
        path: 'reasons-denial'
      },
      {
        component: () => import('@/pages/admin/reasonsdenial/create.vue'),
        hidden: true,
        meta: {
          permissions: [{ ReasonDenial: PermissionLevel.Write }],
          title: 'createReasonDenial'
        },
        name: 'CreateReasonDenial',
        path: 'reasons-denial/create'
      },
      {
        component: () => import('@/pages/admin/reasonsdenial/edit.vue'),
        hidden: true,
        meta: {
          permissions: [{ ReasonDenial: PermissionLevel.Read }],
          title: 'editReasonDenial'
        },
        name: 'EditReasonDenial',
        path: 'reasons-denial/edit/:id(\\d+)'
      },
      {
        component: () => import('@/pages/admin/referalsnoordzij/index.vue'),
        meta: {
          permissions: [{ ReferalNoordzij: PermissionLevel.Read }],
          title: 'menu_referals_noordzij'
        },
        name: 'ReferalsNoordzij',
        path: 'referals-noordzij'
      },
      {
        component: () => import('@/pages/admin/referalsnoordzij/create.vue'),
        hidden: true,
        meta: {
          permissions: [{ ReferalNoordzij: PermissionLevel.Write }],
          title: 'createReferalNoordzij'
        },
        name: 'CreateReferalNoordzij',
        path: 'referals-noordzij/create'
      },
      {
        component: () => import('@/pages/admin/referalsnoordzij/edit.vue'),
        hidden: true,
        meta: {
          permissions: [{ ReferalNoordzij: PermissionLevel.Read }],
          title: 'editReferalNoordzij'
        },
        name: 'EditReferalNoordzij',
        path: 'referals-noordzij/edit/:id(\\d+)'
      },
      {
        component: () => import('@/pages/admin/referalsmunicipality/index.vue'),
        meta: {
          permissions: [{ ReferalMunicipality: PermissionLevel.Read }],
          title: 'menu_referals_municipality'
        },
        name: 'ReferalsMunicipality',
        path: 'referals-municipality'
      },
      {
        component: () => import('@/pages/admin/referalsmunicipality/create.vue'),
        hidden: true,
        meta: {
          permissions: [{ ReferalMunicipality: PermissionLevel.Write }],
          title: 'createReferalMunicipality'
        },
        name: 'CreateReferalMunicipality',
        path: 'referals-municipality/create'
      },
      {
        component: () => import('@/pages/admin/referalsmunicipality/edit.vue'),
        hidden: true,
        meta: {
          permissions: [{ ReferalMunicipality: PermissionLevel.Read }],
          title: 'editReferalMunicipality'
        },
        name: 'EditReferalMunicipality',
        path: 'referals-municipality/edit/:id(\\d+)'
      },
      // #region intrekking aanvrager
      {
        component: () => import('@/pages/admin/withdrawalsapplicant/index.vue'),
        meta: {
          permissions: [{ WithdrawalApplicant: PermissionLevel.Read }],
          title: 'menu_withdrawals_applicant'
        },
        name: 'WithdrawalApplicant',
        path: 'withdrawalsapplicant'
      },
      {
        component: () => import('@/pages/admin/withdrawalsapplicant/create.vue'),
        hidden: true,
        meta: {
          permissions: [{ WithdrawalApplicant: PermissionLevel.Write }],
          title: 'createwithdrawalapplicant'
        },
        name: 'CreateWithdrawalApplicant',
        path: 'withdrawalsapplicant/create'
      },
      {
        component: () => import('@/pages/admin/withdrawalsapplicant/edit.vue'),
        hidden: true,
        meta: {
          permissions: [{ WithdrawalApplicant: PermissionLevel.Write }],
          title: 'editWithdrawalApplicant'
        },
        name: 'EditWithdrawalApplicant',
        path: 'withdrawalsapplicant/edit/:id(\\d+)'
      },
      // #endregion
      {
        component: () => import('@/pages/admin/withdrawalspv/index.vue'),
        meta: {
          permissions: [{ WithdrawalPv: PermissionLevel.Read }],
          title: 'menu_withdrawals_pv'
        },
        name: 'WithdrawalsPv',
        path: 'withdrawals-pv'
      },
      {
        component: () => import('@/pages/admin/withdrawalspv/create.vue'),
        hidden: true,
        meta: {
          permissions: [{ WithdrawalPv: PermissionLevel.Write }],
          title: 'createWithdrawalPv'
        },
        name: 'CreateWithdrawalPv',
        path: 'withdrawals-pv/create'
      },
      {
        component: () => import('@/pages/admin/withdrawalspv/edit.vue'),
        hidden: true,
        meta: {
          permissions: [{ WithdrawalPv: PermissionLevel.Read }],
          title: 'editWithdrawalPv'
        },
        name: 'EditWithdrawalPv',
        path: 'withdrawals-pv/edit/:id(\\d+)'
      },
      {
        component: () => import('@/pages/admin/application/configuration/index.vue'),
        meta: {
          permissions: [{ Configuration: PermissionLevel.Read }],
          title: 'menu_admin_configuration'
        },
        name: 'Configuration',
        path: 'configuration'
      },
      {
        component: () => import('@/pages/admin/application/configuration/edit.vue'),
        hidden: true,
        meta: {
          noCache: true,
          permissions: [{ Configuration: PermissionLevel.Read }],
          title: 'editConfiguration'
        },
        name: 'EditConfiguration',
        path: 'configuration/edit/:id'
      },
      {
        component: () => import('@/pages/admin/maintenance/accesslog/index.vue'),
        meta: {
          permissions: [{ AccessLog: PermissionLevel.Read }],
          title: 'menu_admin_accesslog'
        },
        name: 'Access log',
        path: 'accesslog'
      },
      {
        component: () => import('@/pages/admin/maintenance/eventlog/index.vue'),
        meta: {
          permissions: [{ EventLog: PermissionLevel.Read }],
          title: 'menu_admin_eventlog'
        },
        name: 'Event Log',
        path: 'eventlog'
      },
      ...loanSystemRoutesAdmin
    ],
    component: LazyLayout,
    meta: { icon: 'star', title: 'menu_admin_application' },
    name: 'Application',
    path: '/application'
  } as RouteConfig,
  {
    children: [
      {
        component: () => import('@/pages/user/settings.vue'),
        meta: { noCache: true, title: 'userSettings' },
        name: 'Settings',
        path: 'settings'
      }
    ],
    component: LazyLayout,
    hidden: true,
    path: '/user',
    redirect: 'settings'
  } as RouteConfig
];
