// Keys ougt to be sorted alphabetically, case sensitive.
// To autosort: repeat CTRL+S until all keys are sorted
//  OR
// repeat command until sorted: npm run lint-fix

/* eslint sort-keys-fix/sort-keys-fix: error */
export default {
  Actions: {
    approve: 'Goedkeuren',
    reject: 'Afkeuren',
    withdraw: 'Intrekken'
  },
  FollowUpAction: {
    boardForApproval: 'Dossier ter beoordeling bestuur',
    boardForInformation: 'Dossier ter kennisneming bestuur',
    consultant: 'Voorstel consultant FH',
    manager: 'Actie manager',
    name: 'Vervolgactie'
  },
  LoanStatusChangeTrigger: {
    AboveAgreementLimit: 'Boven de overeenkomst limiet',
    Always: 'Altijd',
    ByManualChange: 'Bij handmatig veranderen',
    BySystemChange: 'Bij systeem veranderen',
    UnderAgreementLimit: 'Onder de overeenkomst limiet',
    actionTypes: 'Acties',
    all: 'Alle',
    loanType: 'Hulp type',
    onAutomaticChange: 'Wanneer automatisch aanmaken?',
    onAutomaticChangeQuestion: 'Wanneer moeten de acties aangemaakt worden?',
    whatLoanTypeQuestion: 'Bij welk hulp type?',
    whenUnderLoanAgreementLimit: 'Wanneer moeten de acties aangemaakt worden?',
    whenUnderLoanAgreementLimitQuestion: 'Wanneer moeten de acties aangemaakt worden?'
  },
  accesslog: {
    accessedAt: 'Datum tijd',
    accessedBy: 'Geopend door',
    accessedResource: 'Gebruikte bron',
    entity: 'Entiteit',
    entityID: 'ID',
    ip: 'IP',
    parameters: 'Parameters'
  },
  auditlog: {
    changeType: 'Type wijziging',
    displayName: 'Label',
    entity: 'Entiteit',
    fieldName: 'Veldnaam',
    mutatedAt: 'Datum wijziging',
    mutatedBy: 'Gebruikersnaam',
    newValue: 'Nieuwe waarde',
    oldValue: 'Oude waarde',
    title: 'Historie {entity}'
  },
  autoUpdate: {
    alertConfirm: 'Herlaad pagina',
    alertMessage:
      'Er is een nieuwe versie van de applicatie beschikbaar. Herlaad de pagina om de applicatie bij te werken.',
    alertTitle: 'Update'
  },
  common: {
    actions: 'Acties',
    actor: 'Actiehouder',
    add: 'Toevoegen',
    address: 'Adres',
    adres: 'Adres',
    adresgegevens: 'Adresgegevens',
    archive: 'Archief',
    billingAddress: 'Factuuradres',
    block_notification_not_saved_warning: 'Notificatie instelling niet opgeslagen. Probeer het later opnieuw',
    board: 'Bestuur',
    browserNotSupported: 'Uw browser ondersteunt deze functionaliteit niet',
    cancel: 'Annuleren',
    city: 'Plaats',
    clearFilters: 'Verwijder alle filters',
    close: 'Sluiten',
    close_unsaved_warning: 'Uw wijzigingen zijn nog niet opgeslagen. Weet u zeker dat u dit scherm wilt sluiten?',
    close_warning: 'Wilt u zeker dat u dit scherm wilt afsluiten?',
    code: 'Code',
    companyAddress: 'Bezoekadres',
    confirm: 'Bevestigen',
    contactgegevens: 'Contactgegevens',
    contractType: 'Contracttype',
    copy: 'Kopieer',
    copyToClipboard: 'Kopiëer naar klembord',
    copyToClipboardFail: 'Kopiëren naar klembord mislukt',
    copyToClipboardSuccess: 'Kopiëren naar klembord gelukt',
    country: 'Land',
    data_created: 'De gegevens zijn opgeslagen',
    data_save_error: 'De gegevens kunnen niet worden opgeslagen',
    data_updated: 'De wijzigingen zijn opgeslagen',
    date: 'Datum',
    dateOfBirth: 'Geboortedatum',
    decimals: 'Decimalen',
    defaultComment: 'Standaardopmerking',
    delete: 'Verwijderen',
    delete_cancelled: 'Verwijderen geannuleerd',
    delete_completed: 'De gegevens zijn verwijderd',
    delete_record_summary_warning: 'Weet u zeker dat u <b>{summary}</b> wilt verwijderen?',
    delete_record_warning: 'Weet u zeker dat u dit wilt verwijderen?',
    department: 'Afdeling',
    description: 'Beschrijving',
    documentType: 'Documenttype',
    documentTypeName: 'Documenttype',
    documents: 'Documenten',
    dontSave: 'Niet opslaan',
    dontSend: 'Niet verzenden',
    dont_show_again_warning: 'Niet meer tonen',
    dossierID: 'Dossiernummer',
    draft: 'Concept',
    ean: 'EAN-13',
    edit: 'Bewerk',
    email: 'E-mailadres',
    emptyString: ' ',
    endDateContract: 'Contract tot',
    error: 'Fout',
    export: 'Export',
    file: 'Bestand',
    filename: 'Bestandsnaam',
    firstName: 'Voornaam',
    gender: 'Geslacht',
    genderPrefix: 'Prefix',
    genderSalutation: 'Aanhef',
    general: 'Algemeen',
    id: 'ID',
    inactive: 'Inactief',
    initials: 'Initialen',
    initialsShort: 'Init.',
    jobFunction: 'Functie',
    lastName: 'Achternaam',
    leave_mail_warning: 'U verlaat deze pagina. Wilt u eerst deze mail verzenden?',
    leave_unsaved_warning: 'U verlaat deze pagina. Wilt u de wijzigingen opslaan?',
    lener: 'Aanvrager',
    leningen: 'Leningen',
    loading: 'Laden ...',
    mailingAddress: 'Postadres',
    manager: 'Manager',
    middleName: 'Tussenvoegsels',
    middleNameShort: 'T.v.',
    mobile: 'Mobiel',
    modified: 'Gewijzigd',
    name: 'Naam',
    no: 'Nee',
    noData: 'Geen data',
    notApplicable: 'Niet van toepassing',
    ok: 'OK',
    opmerkingen: 'Extra informatie',
    partner: 'Partner',
    password: 'Wachtwoord',
    plaats: 'Plaats',
    postalCode: 'Postcode',
    postcode: 'Postcode',
    prefix: 'Tussenvoegsel',
    publish: 'Publiceren',
    quantity: 'Aantal',
    question: 'Vraag',
    readMore: 'Lees meer ...',
    reason: 'Reden',
    reference: 'Referentie',
    refresh: 'Verversen',
    remark: 'Opmerking',
    remarks: 'Opmerkingen',
    role: 'Rol',
    salaryScale: 'Salaris schaal',
    save: 'Opslaan',
    saveClose: 'Opslaan & sluiten',
    saveNext: 'Opslaan & volgende',
    search: 'Zoek',
    send: 'Verzenden',
    sequence: 'Volgorde',
    skip: 'Overslaan',
    space: ' ',
    status: 'Status',
    stay: 'Blijf op pagina',
    streetName: 'Straat',
    streetNumber: 'Huisnummer',
    subject: 'Onderwerp',
    success: 'Succes',
    switchOff: 'Uit',
    switchOn: 'Aan',
    taskstatus: 'Taakstatus',
    telephone: 'Telefoon',
    time: 'Tijd',
    title: 'Titel',
    tussenvoegselLener: 'Tussenvoegsel',
    tussenvoegselPartner: 'Tussenvoegsel',
    type: 'Type',
    unit: 'Eenheid',
    username: 'Gebruikersnaam',
    vatNumber: 'BTW-nummer',
    voorlettersLener: 'Voorletters',
    voorlettersPartner: 'Voorletters',
    warning: 'Waarschuwing',
    workInfo: 'Werk informatie',
    yes: 'Ja'
  },
  configuration: {
    code: 'Code',
    fromDate: 'Geldig vanaf',
    value: 'Waarde'
  },
  divider: {
    adresgegevens: 'ADRESGEGEVENS',
    contactgegevens: 'CONTACTGEGEVENS',
    lener: 'AANVRAGER',
    overigegegevens: 'OVERIG',
    partner: 'PARTNER'
  },
  document: {
    approved: 'Goedgekeurd',
    contentDate: 'Datum inhoud',
    contents: 'Inhoud',
    createDate: 'Datum aangemaakt',
    description: 'Beschrijving',
    documentType: 'Document type',
    dossierID: 'Dossier nr',
    rejectReason: 'Reden afkeuring',
    rejected: 'Afgekeurd',
    status: 'Status'
  },
  dossier: {
    applicant: 'Aanvraag via',
    archiveDate: 'Archiveringsdatum',
    bcCode: 'BC code',
    company: 'Werkzaam bij',
    datumNaarBestuurTb: 'Datum naar bestuur Tb',
    datumNaarBestuurTk: 'Datum naar bestuur Tk',
    debtstep: 'Schuldenladder trede',
    delay: 'Vertraging',
    dossierID: 'Dossiernr',
    dossierNummer: 'Dossiernummer',
    enddateContract: 'Bepaald contract tot',
    function: 'Functie',
    namePartner: 'Naam partner',
    personeelsNummer: 'Personeelsnr',
    reason: 'Reden',
    remarks: 'Extra informatie',
    salaryscale: 'Schaalnummer',
    status: 'Status',
    uniekpersoneelsNummer: 'Uniek Personeelsnummer'
  },
  dossierAction: {
    actingRoleName: 'Actiehouder rol',
    actionStatusName: 'Actiestatus',
    actionTypeName: 'Actietype',
    alertDate: 'Signaleringsdatum',
    comment: 'Opmerking',
    creationDate: 'Aanmaakdatum'
  },
  editor: {
    entityFetchError: 'Dit item bestaat niet (meer) of u heeft geen toegang tot de gegevens van deze pagina.'
  },
  entity: {
    DossierAction: 'Actie'
  },
  error: {
    general_request: 'Er is iets fout gegaan. Probeer het later opnieuw.',
    oneOrMoreInputs: 'De gegevens kunnen niet worden opgeslagen want het formulier bevat fouten.',
    request_401: 'U bent niet geautoriseerd',
    request_403: 'U heeft geen permissie tot deze actie',
    save: 'Er is iets fout gegaan. Probeer het later opnieuw.'
  },
  errorLog: {
    description:
      'Now the management system are basically the form of the spa, it enhances the user experience, but it also increases the possibility of page problems, a small negligence may lead to the entire page deadlock. Fortunately Vue provides a way to catch handling exceptions, where you can handle errors or report exceptions.',
    documentation: 'Document introduction',
    tips: 'Please click the bug icon in the upper right corner'
  },
  errorPages: {
    backToHome: 'Terug naar thuispagina',
    headline401: 'U heeft geen toegang tot deze pagina',
    headline403: 'U heeft geen toegang tot deze pagina',
    headline404: 'Pagina niet gevonden',
    message401: 'Indien u het hier niet mee eens bent, neem dan contact op met uw beheerder.',
    message403: 'Indien u het hier niet mee eens bent, neem dan contact op met uw beheerder.',
    message404: 'Controleer of de ingevoerde URL correct is.',
    return: 'Terug',
    title401: 'Oops',
    title403: 'Toegang geweigerd',
    title404: 'Sorry'
  },
  eventlog: {
    emptyException: 'Geen exception',
    emptyMessage: 'Geen message',
    exception: 'Exception',
    level: 'Level',
    logger: 'Logger',
    message: 'Message',
    occurredAt: 'Datum tijd',
    thread: 'Thread'
  },
  fileExport: {
    generate: 'Exporteer',
    invoiceFileName: 'Facturen bestand',
    surchargeFileName: 'Toeslagen bestand'
  },
  fileUpload: {
    dragAndDropHtml: 'Sleep het te importeren bestand hierheen',
    fileName: 'Bestandsnaam',
    uploadTip: 'Om een bestand te import gebruik drag en drop, of klikt het logo om een bestand te selecteren.'
  },
  forgotPassword: {
    description:
      'U kunt uw wachtwoord opnieuw instellen. Vul het onderstaande formulier in om de instructies per email te ontvangen.',
    done: 'Er is een email verstuurd naar {email}. Volg de instructies die hierin zijn vermeld.',
    submit: 'Verstuur email',
    title: 'Wachtwoord vergeten'
  },
  home: {
    welcomeVisitor: 'Welkom bezoeker'
  },
  language: {
    code: 'ISO 639-1 Code'
  },
  loan: {
    actNumber: 'Aktenr',
    agreed: 'Akkoord',
    agreementAction: 'Actie',
    agreementBoard: 'Akkoord bestuur',
    agreementManager: 'Akkoord manager',
    agreementRemarkBoard: 'Opmerkingen bestuur',
    agreementRemarkManager: 'Opmerkingen manager',
    agreementStatus: 'Status besluitvorming',
    amount: 'Bedrag',
    applicant: 'Aanvraag via',
    bKRCheck: 'BKR check',
    bankstatements: 'Bankafschriften',
    calculateTerms: 'Termijnen berekenen',
    calculateThl: 'Bereken Thl datum',
    dateToBoard: 'Datum naar bestuur',
    debtSecurities: 'Schuldbewijzen',
    debtStep: 'Schuldenladder',
    delay: 'Vertraging',
    delayDate: 'Datum vertraging',
    denied: 'Niet akkoord',
    dossierID: 'Dossiernummer',
    explanationApplicant: 'Toelichting van de aanvrager',
    firstDate: 'Datum eerste inhouding',
    holidayMoney: 'Vakantiegeld %',
    intake: 'intake',
    interest: 'Rente',
    interview: 'Intake gesprek',
    loanType: 'Hulptype',
    oneTimeGrant: 'Eenmalige uitkering %',
    payslips: 'Loonstroken',
    plan: 'Plan van aanpak',
    prlDate: 'BKR Prl datum',
    profitSharing: 'Winstdeling %',
    reason: 'Reden',
    reasonDenial: 'Afwijzingsreden',
    reasonReferalMunicipality: 'Reden verwijzing gemeente',
    reasonReferalNoordzij: 'Reden verwijzing extern',
    reasonRemark: 'Voorstel consultant FH',
    reasonWithdrawalApplicant: 'Reden intrekking aanvrager',
    reasonWithdrawalPv: 'Reden intrekking PV',
    remark: 'Opmerkingen',
    repaymentAmount: 'Aflossingsbedrag',
    requestDate: 'Aanvraag datum',
    requestStatus: 'Aanvraag status',
    requirements: 'Voorwaarden',
    status: 'Status',
    terms: 'Termijnen',
    thirteenthMonth: 'Dertiende maand %',
    thlDate: 'BKR Thl datum'
  },
  login: {
    code: 'Gebruikersnaam',
    forbidden: 'Gebruiker/IP combinatie is geblokkeerd.',
    logIn: 'Inloggen',
    logInWithAnotherAccount: 'Inloggen met een ander account',
    password: 'Wachtwoord',
    title: 'Inloggen',
    unauthorised: 'Deze inloggegevens zijn onjuist.'
  },
  mail: {
    bcc: 'Bcc',
    body: 'Bericht',
    cc: 'Cc',
    error: 'Er is een probleem opgetreden. Uw email is niet verstuurd.',
    from: 'Van',
    subject: 'Onderwerp',
    success: 'Uw email is succesvol verzonden',
    to: 'Aan'
  },
  navbar: {
    dashboard: 'Dashboard',
    language: 'Taal',
    logOut: 'Uitloggen',
    screenfull: 'Volledig scherm',
    size: 'Tekstgrootte',
    theme: 'Theme'
  },
  newPassword: {
    description: 'U kunt nu uw wachtwoord permanent wijzigen.',
    done: 'Uw wachtwoord is gewijzigd.',
    invalidToken: 'Deze link is verlopen.',
    submit: 'Wijzig wachtwoord',
    title: 'Nieuw wachtwoord'
  },
  permission: {
    AccessLog: 'Access log',
    ActionStatus: 'Actie status',
    ActionType: 'Actietypes',
    AgreementBoard: 'Akkoord bestuur',
    AgreementConsultant: 'Akoord consultant',
    AgreementManager: 'Akkoord manager',
    Applicant: 'Aanvragers',
    AuditLog: 'Auditlog inzien',
    BCcode: 'BC codes',
    Company: 'Bedrijven',
    Configuration: 'Instellingen',
    Country: 'Landen',
    DebtStep: 'Schuldenladder',
    Delay: 'Vertragingen',
    Document: 'Documenten',
    DocumentStatus: 'Document statussen',
    DocumentType: 'Document types',
    Domain: 'Domeinen',
    Dossier: 'Dossiers',
    DossierAction: 'Acties',
    EventLog: 'Event log',
    Function: 'Functies',
    Gender: 'Geslachten',
    Language: 'Talen',
    Loan: 'Hulptype',
    LoanAgreement: 'Akkoord indienen',
    LoanStatusChangeTrigger: 'Status triggers',
    Loantype: 'Hulptype types',
    Option: 'Optielijsten',
    Permission: 'Permissies',
    Reason: 'Redenen',
    ReasonDenial: 'Afwijzingsredenen',
    ReferalMunicipality: 'Doorverwijzingen gemeente',
    ReferalNoordzij: 'Doorverwijzingen extern',
    Report: 'Rapporten',
    Role: 'Rollen',
    Status: 'Statussen',
    Template: 'Sjablonen',
    Translation: 'Vertalingen',
    User: 'Gebruikers',
    WithdrawalApplicant: 'Intrekkingen aanvrager',
    WithdrawalPv: 'Intrekkingen pv',
    execute: 'Uitvoeren',
    none: 'Geen',
    pageIsReadonly: 'U heeft geen rechten om de gegevens op deze pagina te wijzigen.',
    read: 'Lezen',
    roles: 'Uw rollen',
    selectPlaceholder: 'Kies het toegangsniveau',
    switchRoles: 'Rol wisselen',
    tabPermissions: 'Permissies',
    tabTaskTypes: 'Taaksoorten',
    write: 'Schrijven'
  },
  pickrec: {
    chooseEntryFor: 'Selecteer "{caption}"',
    itemNotFound: "'{caption}' niet gevonden",
    multiSearchFiltersLabel: 'Filters',
    suggestionsMayBeMore: 'Er zijn mogelijk meer zoekresulten',
    suggestionsRestCount: 'Er zijn nog {count} andere zoekresultaten'
  },
  report: {
    dateApplicationBKR: 'Aanmelding BKR',
    dateAuthorizationDocumentReceived: 'Akte machtiging ontvangen',
    dateAuthorizationDocumentSent: 'Akte machtiging verzonden',
    dateAuthorizationHR: 'Machtiging naar HR',
    dateBoardApproved: 'Toegewezen bestuur',
    dateBoardDenied: 'Afgewezen bestuur',
    dateBoardRevoked: 'Ingetrokken bestuur',
    dateCancelationBKR: 'Afmelding BKR',
    dateFirstPayment: 'Eerste betaling',
    dateIrrecoverableBKR: 'Niet inbaar BKR',
    dateMandateApproved: 'Mandaat toegewezen',
    dateMandateDenied: 'Mandaat afgewezen',
    datePayed: 'Betaald',
    dateRedemptionBKR: 'Afgelost BKR',
    dateRedemptionEstimateBKR: 'Afgelost BKR (geschat)',
    dateToBoard: 'Naar bestuur',
    dateToBoardDateTb: 'Naar bestuur Tb',
    dateToBoardDateTk: 'Naar bestuur Tk'
  },
  request: {
    networkError: 'Het netwerk is op dit moment niet bereikbaar.'
  },
  requestActions: {
    generate: 'Genereer acties',
    loanPlaceholder: 'Select lening'
  },
  requestDocument: {
    generate: 'Genereer documenten',
    loanPlaceholder: 'Selecteer lening',
    templatePlaceholder: 'Selecteer sjabloon'
  },
  roleTaskType: {
    false: 'Ja',
    true: 'Nee'
  },
  route: {
    Table: 'Table',
    avatarUpload: 'Avatar Upload',
    clipboardDemo: 'Clipboard',
    complexTable: 'Complex Table',
    componentIndex: 'Introduction',
    componentMixin: 'Mixin',
    components: 'Components',
    countTo: 'CountTo',
    createActionStatus: 'Actie status aanmaken',
    createActionType: 'Actietype aanmaken',
    createApplicant: 'Aanvraag via aanmaken',
    createBCcode: 'BC code aanmaken',
    createCompany: 'Bedrijf aanmaken',
    createCountry: 'Land aanmaken',
    createDebtStep: 'Schuldentrede aanmaken',
    createDelay: 'Vertraging aanmaken',
    createDocument: 'Document aanmaken',
    createDocumentStatus: 'Document status aanmaken',
    createDocumentType: 'Document type aanmaken',
    createDossier: 'Dossier aanmaken',
    createDossierAction: 'Actie aanmaken',
    createFunction: 'Functie aanmaken',
    createGender: 'Geslacht aanmaken',
    createLanguage: 'Taal aanmaken',
    createLoan: 'Lening aanmaken',
    createLoanStatusChangeTrigger: 'Status trigger aanmaken',
    createLoanType: 'Lening type aanmaken',
    createReason: 'Reden aanmaken',
    createReasonDenial: 'Afwijzingsreden aanmaken',
    createReferalMunicipality: 'Doorverwijzing gemeente aanmaken',
    createReferalNoordzij: 'Doorverwijzing extern aanmaken',
    createRole: 'Rol aanmaken',
    createStatus: 'Status aanmaken',
    createTemplate: 'Sjabloon aanmaken',
    createUser: 'Gebruiker aanmaken',
    createWithDrawalApplicant: 'Intrekking aanvrager aanmaken',
    createWithdrawalPv: 'Intrekking pv aanmaken',
    customTreeTable: 'Custom TreeTable',
    dashboard: 'Dashboard',
    directivePermission: 'Directive Permission',
    documentation: 'Documentation',
    dragDialog: 'Drag Dialog',
    dragTable: 'Drag Table',
    dropzone: 'Dropzone',
    dynamicTable: 'Dynamic Table',
    editActionStatus: 'Actie status bewerken',
    editActionType: 'Actietype bewerken',
    editApplicant: 'Aanvraag via bewerken',
    editBCcode: 'BC code bewerken',
    editCompany: 'Bedrijf bewerken',
    editConfiguration: 'Instelling aanpassen',
    editCountry: 'Land bewerken',
    editDebtStep: 'Schuldentrede bewerken',
    editDelay: 'Vertraging bewerken',
    editDocument: 'Document bewerken',
    editDocumentStatus: 'Document status bewerken',
    editDocumentType: 'Document type bewerken',
    editDossier: 'Dossier bewerken',
    editDossierAction: 'Actie bewerken',
    editFunction: 'Functie bewerken',
    editGender: 'Geslacht bewerken',
    editLanguage: 'Taal bewerken',
    editLoan: 'Lening bewerken',
    editLoanStatusChangeTrigger: 'Status trigger bewerken',
    editLoanType: 'Lening type bewerken',
    editReason: 'Reden bewerken',
    editReasonDenial: 'Afwijzingsreden bewerken',
    editReferalMunicipality: 'Doorverwijzing gemeente bewerken',
    editReferalNoordzij: 'Doorverwijzing extern bewerken',
    editRole: 'Rol bewerken',
    editStatus: 'Status bewerken',
    editTemplate: 'Sjabloon bewerken',
    editTranslation: 'Vertaling bewerken',
    editUser: 'Gebruiker bewerken',
    editWithdrawalApplicant: 'Intrekking aanvrager bewerken',
    editWithdrawalPv: 'Intrekking pv bewerken',
    editdebtStep: 'Schuldentrede bewerken',
    errorLog: 'Error Log',
    errorPages: 'Error Pages',
    example: 'Example',
    excel: 'Excel',
    exportExcel: 'Export Excel',
    exportZip: 'Export Zip',
    externalLink: 'External Link',
    form: 'Form',
    guide: 'Guide',
    home: 'Home',
    i18n: 'I18n',
    icons: 'Icons',
    inlineEditTable: 'Inline Edit',
    introduction: 'Introduction',
    jsonEditor: 'JSON Editor',
    markdown: 'Markdown',
    menu_action_status: 'Actie Status',
    menu_action_types: 'Actietypes',
    menu_admin: 'Beheer',
    menu_admin_accesslog: 'Access Log',
    menu_admin_application: 'Applicatie',
    menu_admin_company_records: 'Bedrijfsgegevens',
    menu_admin_configuration: 'Instellingen',
    menu_admin_countries: 'Landen',
    menu_admin_eventlog: 'Event Log',
    menu_admin_languages: 'Talen',
    menu_admin_options: 'Keuzelijsten',
    menu_admin_roles: 'Rollen',
    menu_admin_security: 'Rollen en gebruikers',
    menu_admin_translations: 'Vertalingen',
    menu_admin_users: 'Gebruikers',
    menu_agreement_board: 'Dossiers beoordeling bestuur',
    menu_agreement_manager: 'Dossiers beoordeling manager',
    menu_applicants: 'Aanvraag via',
    menu_bccodes: 'BC codes',
    menu_companies: 'Werkzaam bij',
    menu_debt_ladder: 'Schuldenladder',
    menu_delays: 'Vertragingen',
    menu_document_statuses: 'DocumentStatussen',
    menu_document_types: 'DocumentTypes',
    menu_documents: 'Documenten',
    menu_dossier_actions: 'Acties',
    menu_dossiers: 'Dossiers',
    menu_functions: 'Functies',
    menu_genders: 'Geslachten',
    menu_information_board: 'Dossiers ter kennisneming bestuur',
    menu_loan_status_change_triggers: 'Status Triggers',
    menu_loan_types: 'HulpTypes',
    menu_loans: 'Leningen',
    menu_loansystem: 'Leningensysteem',
    menu_reasons: 'Redenen',
    menu_reasons_denial: 'Afwijzingsredenen',
    menu_referals_municipality: 'Doorverwijzing gemeente',
    menu_referals_noordzij: 'Doorverwijzing extern',
    menu_reportActions: 'Actie rapport',
    menu_reportDossiersNaarBestuurTb: 'Dossiers naar bestuur Tb',
    menu_reportDossiersNaarBestuurTk: 'Dossiers naar bestuur Tk',
    menu_reports: 'Rapporten',
    menu_reporttotaleLeningenGiften: 'Totale leningen en giften',
    menu_statuses: 'Statussen',
    menu_templates: 'Sjablonen',
    menu_withdrawals_applicant: 'Intrekkingen Aanvrager',
    menu_withdrawals_pv: 'Intrekkingen PV',
    page401: '401',
    page403: '403',
    page404: '404',
    pagePermission: 'Page Permission',
    permission: 'Permission',
    selectExcel: 'Export Selected',
    splitPane: 'SplitPane',
    sticky: 'Sticky',
    tab: 'Tab',
    theme: 'Theme',
    tinymce: 'Tinymce',
    treeTable: 'Tree Table',
    uploadExcel: 'Upload Excel',
    userSettings: 'Instellingen gebruiker',
    viewLoan: 'Lening bekijken',
    zip: 'Zip'
  },
  size: {
    medium: 'Normaal',
    mini: 'Kleinst',
    small: 'Klein'
  },
  stringInsert: {
    btnText: 'Invoegen...',
    closeOnInsert: 'Sluit dialoog na invoegen',
    textIntro: 'Klik op een tekst om deze in te voegen.',
    textIntroUnavailable: 'Er zijn geen teksten om in te voegen.',
    textTitle: 'Tekst invoegen',
    toastInsert: 'Ingevoegd: {name}'
  },
  table: {
    actions: 'Acties',
    searchPlaceholder: 'Typ om te zoeken',
    searchSelectPlaceholder: 'Selecteer om te zoeken'
  },
  tabs: {
    acties: 'Acties',
    adres: 'Adres',
    documenten: 'Documenten',
    lener: 'Aanvrager',
    leningen: 'Hulptype',
    overig: 'Werkinfo',
    partner: 'Partner'
  },
  tagsView: {
    close: 'Sluiten',
    closeAll: 'Alle sluiten',
    closeOthers: 'Andere sluiten',
    refresh: 'Verversen'
  },
  textArea: {
    placeholder: 'Uw tekst'
  },
  translation: {
    code: 'Vertalingcode'
  },
  user: {
    avatar: 'Profielafbeelding',
    avatarInfo: 'URL naar profielafbeelding',
    newPassword: 'Nieuw wachtwoord',
    newPasswordInfo: 'Laat leeg als u uw wachtwoord niet wilt wijzigen',
    oldPassword: 'Huidig wachtwoord',
    oldPasswordInfo: 'Voer ter controle uw huidige wachtwoord in',
    oldPasswordInvalid: 'U heeft niet het correcte huidige wachtwoord opgegeven',
    passwordChanged: 'Uw wachtwoord is aangepast',
    repeatPassword: 'Herhaal wachtwoord',
    repeatPasswordInfo: 'Herhaal ter controle uw nieuwe wachtwoord',
    roles: 'Rollen'
  },
  validation: {
    complexity: 'Kies complexiteit',
    ean: 'Deze barcode is incorrect',
    email: 'Dit is een ongeldig e-mailadres',
    error: {
      alphanumeric: 'Minstens 1 symbool is vereist',
      digit: 'Minstens 1 getal is vereist',
      isFromDatabase: 'Het wachtwoord kan niet worden gevalideerd, omdat de server niet bereikbaar is.',
      itemNotFound: 'Bestand is reeds verwijderd.',
      length: 'Minstens {count} karakters zijn vereist',
      lowercase: 'Minstens 1 kleine letter is vereist',
      passwordComplexity: 'Het wachtwoord voldoet niet aan de vereiste complexiteit',
      uppercase: 'Minstens 1 hoofdletter is vereist'
    },
    exceedMax: 'Deze waarde is te groot',
    formValidationFailed: 'Dit formulier bevat fouten. Controleer uw invoer en probeer het opnieuw',
    numeric: 'Alleen cijfers toegestaan',
    oldPasswordRequired: 'Vul het oude wachtwoord in',
    repeatPasswordMismatch: 'Twee wachtwoorden komen niet overeen',
    required: 'Dit veld is vereist',
    unique: 'Deze waarde is niet uniek'
  },
  validationErrorsMessage: 'Het bestand is niet ge',
  wizard: {
    next: 'Volgende',
    prev: 'Terug',
    skip: 'Overslaan'
  }
};
