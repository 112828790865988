/*eslint no-unused-vars: "error"*/
import * as accesslogOptions from './options/accesslog';
import * as eventlogOptions from './options/eventlog';
import * as permissionOptions from './options/permission';
import * as optionOptions from './options/option';
import * as roleOptions from './options/role';
import * as userOptions from './options/user';

// PLOP: Import option files
import * as reasonDenialOptions from './options/reasondenial';
import * as referalNoordzijOptions from './options/referalnoordzij';
import * as referalMunicipalityOptions from './options/referalmunicipality';
import * as withdrawalPvOptions from './options/withdrawalpv';
import * as withdrawalApplicantOptions from './options/withdrawalapplicant';
import * as functionOptions from './options/applicantfunction';
import * as templateOptions from './options/template';
import * as documentStatusOptions from './options/documentstatus';
import * as documentOptions from './options/document';
import * as documentTypeOptions from './options/documenttype';
import * as dossierActionOptions from './options/dossieraction';
import * as actionStatusOptions from './options/actionstatus';
import * as actionTypeOptions from './options/actiontype';
import * as loanOptions from './options/loan';
import * as loanAgreementOptions from './options/loanagreement';
import * as loanTypeOptions from './options/loantype';
import * as delayOptions from './options/delay';
import * as companyOptions from './options/company';
import * as bCcodeOptions from './options/bccode';
import * as debtStepOptions from './options/debtstep';
import * as reasonOptions from './options/reason';
import * as statusOptions from './options/status';
import * as applicantOptions from './options/applicant';
import * as genderOptions from './options/gender';
import * as dossierOptions from './options/dossier';
import * as dossiersBoardOptions from './options/dossier';
import * as newActionOptions from './options/newaction';
import * as auditlogOptions from './options/auditlog';
// eslint-disable-next-line
import { RouteParent, CrudStore, RouteSpecification } from './interfaces';

const Security = {
  name: 'Security',
  srcPath: '@/pages/admin/security/',
  i18nTitlePrefix: 'menu_security_'
} as RouteParent;

const Application = {
  name: 'Application',
  srcPath: '@/pages/admin/application/',
  i18nTitlePrefix: 'menu_application_'
} as RouteParent;

const Company = {
  name: 'Company',
  srcPath: '@/pages/admin/company/',
  i18nTitlePrefix: 'menu_company_'
} as RouteParent;

const routeParentPages = {
  Security,
  Application,
  Company
};

export const routerPages = {};
Object.keys(routeParentPages).forEach(key => {
  routerPages[routeParentPages[key].name] = [];
});

const eventlog = {
  permissionName: 'Eventlog',
  requestUrl: '/eventlog',
  store: 'eventlog',
  backendEntityIDField: 'eventLogID',
  options: eventlogOptions
} as CrudStore;

const accesslog = {
  permissionName: 'Accesslog',
  requestUrl: '/accesslog',
  store: 'accesslog',
  backendEntityIDField: 'accessLogID',
  options: accesslogOptions
} as CrudStore;

const configuration = {
  permissionName: 'Configuration',
  requestUrl: '/configuration',
  store: 'configuration',
  backendEntityIDField: 'configurationID'
} as CrudStore;

const permission = {
  permissionName: 'Permission',
  requestUrl: '/permission',
  store: 'permission',
  backendEntityIDField: 'permissionID',
  options: permissionOptions
} as CrudStore;

const option = {
  permissionName: 'Option',
  requestUrl: '/option',
  store: 'option',
  backendEntityIDField: 'optionID',
  options: optionOptions
} as CrudStore;

const role = {
  permissionName: 'Role',
  requestUrl: '/role',
  store: 'role',
  backendEntityIDField: 'roleID',
  options: roleOptions
} as CrudStore;

const user = {
  permissionName: 'User',
  requestUrl: '/user',
  store: 'user',
  backendEntityIDField: 'userID',
  options: userOptions,
  route: {
    parent: Security,
    listViewName: 'Users'
  }
} as CrudStore;

const language = {
  permissionName: 'Language',
  requestUrl: '/language',
  store: 'language',
  backendEntityIDField: 'languageID'
} as CrudStore;

const country = {
  permissionName: 'Country',
  requestUrl: '/country',
  store: 'country',
  backendEntityIDField: 'countryID'
} as CrudStore;

const translation = {
  permissionName: 'Translation',
  requestUrl: '/string-description',
  store: 'translation',
  backendEntityIDField: 'stringDescriptionID'
} as CrudStore;
const auditlog = {
  permissionName: 'AuditLog',
  requestUrl: '/auditlog',
  store: 'auditLog',
  options: auditlogOptions
} as CrudStore;

const template = {
  permissionName: 'Template',
  requestUrl: '/template',
  store: 'template',
  options: templateOptions,
  backendEntityIDField: 'templateID'
} as CrudStore;
const documentstatus = {
  permissionName: 'DocumentStatus',
  requestUrl: '/document-status',
  store: 'documentStatus',
  options: documentStatusOptions,
  backendEntityIDField: 'documentStatusID'
} as CrudStore;
const document = {
  permissionName: 'Document',
  requestUrl: '/document',
  store: 'document',
  options: documentOptions,
  backendEntityIDField: 'documentID'
} as CrudStore;
const documenttype = {
  permissionName: 'DocumentType',
  requestUrl: '/document-type',
  store: 'documentType',
  options: documentTypeOptions,
  backendEntityIDField: 'documentTypeID'
} as CrudStore;
const dossieraction = {
  permissionName: 'DossierAction',
  requestUrl: '/dossier-action',
  store: 'dossierAction',
  options: dossierActionOptions,
  backendEntityIDField: 'dossierActionID'
} as CrudStore;
const actiontype = {
  permissionName: 'ActionType',
  requestUrl: '/action-type',
  store: 'actionType',
  options: actionTypeOptions,
  backendEntityIDField: 'actionTypeID'
} as CrudStore;
const newaction = {
  permissionName: 'DossierAction',
  requestUrl: '/action-type/new-action',
  store: 'newactionType',
  options: newActionOptions
};
const actionstatus = {
  permissionName: 'ActionStatus',
  requestUrl: '/action-status',
  store: 'actionStatus',
  options: actionStatusOptions,
  backendEntityIDField: 'actionStatusID'
} as CrudStore;
const loan = {
  permissionName: 'Loan',
  requestUrl: '/loan',
  store: 'loan',
  options: loanOptions,
  backendEntityIDField: 'loanID'
} as CrudStore;
const loanAgreement = {
  permissionName: 'LoanAgreement',
  requestUrl: '/loanagreement',
  store: 'loanagreement',
  options: loanAgreementOptions,
  backendEntityIDField: 'loanAgreementID'
} as CrudStore;
const loantype = {
  permissionName: 'Loantype',
  requestUrl: '/loan-type',
  store: 'loanType',
  options: loanTypeOptions,
  backendEntityIDField: 'loanTypeID'
} as CrudStore;
const delay = {
  permissionName: 'Delay',
  requestUrl: '/delay',
  store: 'delay',
  options: delayOptions,
  backendEntityIDField: 'delayID'
} as CrudStore;
const company = {
  permissionName: 'Company',
  requestUrl: '/company',
  store: 'company',
  options: companyOptions,
  backendEntityIDField: 'companyID'
} as CrudStore;
const bccode = {
  permissionName: 'BCcode',
  requestUrl: '/bccode',
  store: 'bccode',
  options: bCcodeOptions,
  backendEntityIDField: 'bCcodeID'
} as CrudStore;
const debtstep = {
  permissionName: 'DebtStep',
  requestUrl: '/debt-step',
  store: 'debtStep',
  options: debtStepOptions,
  backendEntityIDField: 'debtStepID'
} as CrudStore;
const reason = {
  permissionName: 'Reason',
  requestUrl: '/reason',
  store: 'reason',
  options: reasonOptions,
  backendEntityIDField: 'reasonID'
} as CrudStore;
const status = {
  permissionName: 'Status',
  requestUrl: '/status',
  store: 'status',
  options: statusOptions,
  backendEntityIDField: 'statusID'
} as CrudStore;
const applicant = {
  permissionName: 'Applicant',
  requestUrl: '/applicant',
  store: 'applicant',
  options: applicantOptions,
  backendEntityIDField: 'applicantID'
} as CrudStore;
const applicantfunction = {
  permissionName: 'Function',
  requestUrl: '/function',
  store: 'applicantfunction',
  options: functionOptions,
  backendEntityIDField: 'functionID'
} as CrudStore;
const gender = {
  permissionName: 'Gender',
  requestUrl: '/gender',
  store: 'gender',
  options: genderOptions,
  backendEntityIDField: 'genderID'
} as CrudStore;
const dossier = {
  permissionName: 'Dossier',
  requestUrl: '/dossier',
  store: 'dossier',
  options: dossierOptions,
  backendEntityIDField: 'dossierID'
} as CrudStore;
const dossiersBoard = {
  permissionName: 'DossiersBoard',
  requestUrl: '/dossier/DossiersBoard',
  store: 'dossiersBoard',
  options: dossiersBoardOptions,
  backendEntityIDField: 'dossierID'
} as CrudStore;
const dossiersBoardTk = {
  permissionName: 'DossiersBoard',
  requestUrl: '/dossier/DossiersBoardTk',
  store: 'dossiersBoardTk',
  options: dossiersBoardOptions,
  backendEntityIDField: 'dossierID'
} as CrudStore;
const dossiersManager = {
  permissionName: 'DossiersManager',
  requestUrl: '/dossier/DossiersManager',
  store: 'dossiersManager',
  options: dossiersBoardOptions,
  backendEntityIDField: 'dossierID'
} as CrudStore;
const dossierinfo = {
  permissionName: 'Dossier',
  requestUrl: '/dossier/DossierInformation',
  store: 'dossierinfo',
  backendEntityIDField: 'dossierID'
} as CrudStore;
const actionReport = {
  permissionName: 'Report',
  requestUrl: '/Report/ActionReport',
  store: 'actionReport'
} as CrudStore;
const dossiersTkReport = {
  permissionName: 'Report',
  requestUrl: '/Report/DossiersToBoardTk',
  store: 'dossiersTkReport'
} as CrudStore;
const dossiersTbReport = {
  permissionName: 'Report',
  requestUrl: '/Report/DossiersToBoardTb',
  store: 'dossiersTbReport'
} as CrudStore;
const dossiersOverviewReport = {
  permissionName: 'Report',
  requestUrl: '/Report/DossiersOverviewReport',
  store: 'dossiersOverviewReport'
} as CrudStore;
const withdrawalApplicant = {
  permissionName: 'WithdrawalApplicant',
  requestUrl: '/withdrawal-applicant',
  store: 'withdrawalApplicant',
  options: withdrawalApplicantOptions,
  backendEntityIDField: 'withdrawalApplicantID'
} as CrudStore;
// PLOP: Crud store modules
const loanstatuschangetrigger = {
  permissionName: 'LoanStatusChangeTrigger',
  requestUrl: '/loan-status-change-trigger',
  store: 'loanStatusChangeTrigger',
  backendEntityIDField: 'loanStatusChangeTriggerID'
} as CrudStore;
const reasonDenial = {
  permissionName: 'ReasonDenial',
  requestUrl: '/reason-denial',
  store: 'reasonDenial',
  options: reasonDenialOptions,
  backendEntityIDField: 'reasonDenialID'
} as CrudStore;
const referalnoordzij = {
  permissionName: 'ReferalNoordzij',
  requestUrl: '/referal-noordzij',
  store: 'referalNoordzij',
  options: referalNoordzijOptions,
  backendEntityIDField: 'referalNoordzijID'
} as CrudStore;
const referalmunicipality = {
  permissionName: 'ReferalMunicipality',
  requestUrl: '/referal-municipality',
  store: 'referalMunicipality',
  options: referalMunicipalityOptions,
  backendEntityIDField: 'referalMunicipalityID'
} as CrudStore;
const withdrawalpv = {
  permissionName: 'WithdrawalPv',
  requestUrl: '/withdrawal-pv',
  store: 'withdrawalPv',
  options: withdrawalPvOptions,
  backendEntityIDField: 'withdrawalPvID'
} as CrudStore;
const entities = [
  loanstatuschangetrigger,
  reasonDenial,
  referalnoordzij,
  referalmunicipality,
  withdrawalpv,
  withdrawalApplicant,
  applicantfunction,
  template,
  documentstatus,
  document,
  documenttype,
  dossieraction,
  actionstatus,
  actiontype,
  loan,
  loanAgreement,
  loantype,
  delay,
  company,
  bccode,
  debtstep,
  reason,
  status,
  applicant,
  gender,
  dossier,
  dossiersBoard,
  dossiersBoardTk,
  dossiersManager,
  dossierinfo,
  eventlog,
  accesslog,
  configuration,
  language,
  permission,
  option,
  role,
  user,
  country,
  translation,
  auditlog,
  actionReport,
  dossiersTkReport,
  dossiersTbReport,
  dossiersOverviewReport,
  newaction
];

export default entities;
